export const news = [
  {
    title:
      "Seamless Logistics at FESPA 2025 – Behind the Scenes with ELF Shipping",
    desc: "ELF Shipping played a crucial role in ensuring smooth and efficient logistics at FESPA 2025. From global freight forwarding to on-site handling, we ensured exhibitors' materials arrived safely and on time, enabling a flawless showcase of printing and signage innovations.",
    date: "20-Jan-2025",
    img: "./Images/news/FESPA_2025.png",
    alt: "ELF Shipping managing logistics at FESPA 2025",
  },
  {
    title: "Effortless Medical Logistics at Arab Health 2025  ",
    desc: "Behind the scenes at Arab Health 2025, ELF Shipping expertly handled the logistics of medical equipment and exhibition materials. Our team ensured secure customs clearance, timely deliveries, and on-site coordination, making the event a success for exhibitors and attendees alike.",
    date: "27-Jan-2025",
    img: "./Images/news/Arab_Health_2025.png",
    alt: "ELF Shipping's logistics operations at Arab Health 2025",
  },

  {
    title: "ELF Shipping Expands Its Warehousing Capacity to Serve You Better",
    desc: "We're excited to announce the expansion of our warehousing capabilities. This strategic move allows us to better serve your storage and distribution needs, ensuring efficient and reliable logistics solutions.",
    date: "10-Dec-2023",
    img: "./Images/news/Expanded-warehouse.png",
    alt: "ELF Shipping's expanded warehousing facility",
  },
  {
    title: "ELF Shipping Expands Its Warehousing Capacity to Serve You Better",
    desc: "We're excited to announce the expansion of our warehousing capabilities. This strategic move allows us to better serve your storage and distribution needs, ensuring efficient and reliable logistics solutions.",
    date: "10-Dec-2023",
    img: "./Images/news/Expanded-warehouse.png",
    alt: "ELF Shipping's expanded warehousing facility",
  },
  {
    title: "Securing the Future: ELF Shipping at Intersec Expo 2024",
    desc: "ELF Shipping is proud to be a part of Intersec Expo 2024. We're committed to ensuring the secure and efficient transportation of critical materials for this prestigious event.",
    date: "17-Jan-2024",
    img: "./Images/news/Intersec-Expo-2024.png",
    alt: "ELF Shipping participation at Intersec Expo 2024",
  },
  {
    title: "Powering FESPA Middle East 2024 with Seamless Logistics",
    desc: "ELF Shipping is proud to be the official freight forwarder and onsite handling agent for FESPA Middle East 2024. We're committed to ensuring a seamless and efficient logistics experience for this prestigious event.",
    date: "29-Jan-2024",
    img: "./Images/news/Fespa-2024.png",
    alt: "ELF Shipping logistics at FESPA Middle East 2024",
  },
  {
    title: "ELF Shipping at Arab Health 2024",
    desc: "Join us at Arab Health 2024! ELF Shipping is your trusted partner, ensuring seamless logistics for this global healthcare event. We're committed to powering the future of healthcare.",
    date: "29-Jan-2024",
    img: "./Images/news/Arab-Health-2024.png",
    alt: "ELF Shipping's logistics support for Arab Health 2024",
  },
  {
    title: "ELF Shipping Wins Best Domestic Agent Award",
    desc: "ELF Shipping has won the prestigious Best Domestic Agent Award at the 2024 IELA Congress.",
    date: "21-July-2024",
    img: "./Images/news/logistics-award.png",
    alt: "ELF Shipping wins Best Domestic Agent Award 2024",
  },
  {
    title: "Powering Saudi Air Expo 2024: Seamless Logistics Delivered",
    desc: "ELF Shipping is proud to be the Official Freight Forwarder and On-site Handling Agent for Saudi Air Expo 2024. We ensure the smooth and efficient handling of all cargo, from setup to showtime.",
    date: "15-Nov-2024",
    img: "./Images/news/Saudi Airport.png",
    alt: "ELF Shipping's logistics management for Saudi Air Expo 2024",
  },
  {
    title: "Beautyworld Middle East 2024",
    desc: "ELF Shipping successfully handled the logistics for Beautyworld Middle East 2024, ensuring the smooth delivery of diverse cargo to the Dubai exhibition.",
    date: "01-Nov-2024",
    img: "./Images/news/Beuty-world.png",
    alt: "ELF Shipping's logistics support for Beautyworld Middle East 2024",
  },
  {
    title: "Fueling the Energy Sector at ADIPEC 2024",
    desc: "ELF Shipping powered the energy sector at ADIPEC 2024, ensuring seamless logistics for specialized cargo.",
    date: "04-Nov-2024",
    img: "./Images/news/adipec.png",
    alt: "ELF Shipping at ADIPEC 2024 handling energy sector logistics",
  },
  {
    title: "Mastering Logistics at GITEX 2024",
    desc: "ELF Shipping powered global trade at GITEX 2024, showcasing our expertise in handling cargo with precision and speed.",
    date: "14-Oct-2024",
    img: "./Images/news/Gitex.png",
    alt: "ELF Shipping showcasing logistics expertise at GITEX 2024",
  },
  {
    title: "ELF Shipping: Delivering Freshness to Gulfood 2024",
    desc: "ELF Shipping ensures every shipment arrives fresh and on time from around the world to the Gulfood stage with precision and care.",
    date: "05-Nov-2024",
    img: "./Images/news/Gulfood.png",
    alt: "ELF Shipping ensures fresh deliveries for Gulfood 2024",
  },
];
