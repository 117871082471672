import React, { useEffect } from "react";
import { Layout } from "../../components/layout/Layout";
import Navbar from "../../components/navbar/Navbar";
import { news } from "../../data/news";
import AOS from "aos";
import "aos/dist/aos.css";
import { BannerMain } from "../services-pages/components/Banner";
import SocialMedia from "./SocialMedia";
import Footer from "../../components/footer/Footer";
import { contactus } from "../../data/contact";
import CookieBanner from "../../components/cookie/CookieBanner";

function truncateText(text, maxLength) {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + "..."; // Add ellipsis if truncated
}

function News() {
  const sortedNews = news.sort((a, b) => new Date(b.date) - new Date(a.date));

  // Display the most recent news in the main div, followed by the rest
  const mostRecentNews = sortedNews[0];
  const remainingNews = sortedNews.slice(1);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
    });
  }, []);

  return (
    <div className="bg-bgWhite">
      <Navbar />
      <CookieBanner />
      <div className="pt-4 sm:pt-6 md:pt-16 lg:pt-12 xl:pt-16 bg-bgWhite">
        <Layout>
          <h1
            data-aos="fade-up"
            data-aos-delay="300"
            className="font-arimo text-3xl sm:text-4xl md:text-5xl lg:text-6xl 2xl:text-7xl font-semibold"
          >
            News & Events
          </h1>
          <div className="flex flex-col lg:flex-row ">
            <div className="lg:w-3/6"></div>
            <p
              data-aos="fade-up"
              data-aos-delay="600"
              className="mt-3 md:mt-5 xl:mt-12 lg:w-2/6 self-end"
            >
              Stay updated with the latest news and insights from ELF Shipping.
              Our "News and Events" section covers industry trends, company
              milestones, and valuable logistics information.
            </p>
          </div>

          <>
            {/* Main div for the most recent news */}
            <div className="maindiv mt-8 md:mt-10 lg:mt-16 flex flex-col lg:flex-row lg:gap-8">
              <img
                data-aos="zoom-in-right"
                data-aos-delay="1000" // Make sure this appears after the heading and description
                src={mostRecentNews.img}
                alt={mostRecentNews.alt}
                className="rounded-2xl lg:w-1/2 2xl:w-1/3 drop-shadow-xl min-w-[45vw]"
              />
              <div className="flex flex-col items-center justify-center flex-grow">
                <h3
                  data-aos="fade-up"
                  data-aos-delay="1300" // Delayed for correct sequence
                  className="self-start mt-4 md:mt-6 lg:mt-0 text-lg sm:text-xl md:text-2xl lg:text-3xl 2xl:text-4xl font-semibold leading-9"
                >
                  {mostRecentNews.title}
                </h3>
                <p
                  data-aos="fade-up"
                  data-aos-delay="1500" // Ensuring the description shows after the heading
                  className="self-start mt-3 md:mt-4 xl:mt-5"
                >
                  {mostRecentNews.desc}
                </p>
                <button
                  data-aos="fade-up"
                  data-aos-delay="1500" // Slightly delayed to show after the description
                  className="mt-5 md:mt-8 xl:mt-6 self-start text-white bg-peach hover:text-peach hover:bg-white font-semibold
                    border-2 border-peach hover:border-peach p-1 px-3 md:p-2 md:px-5 font-arimo
                    rounded-full text-base md:text-lg lg:text-xl"
                >
                  {mostRecentNews.date}
                </button>
              </div>
            </div>

            {/* Display the remaining news items in a grid */}
            <div className="mt-8 md:mt-10 lg:mt-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-12 lg:gap-y-16 gap-8">
              {remainingNews.map((item, index) => (
                <div
                  key={index}
                  className="news-card flex flex-col justify-between h-full bg-gray-100 shadow-lg rounded-xl transform transition-transform duration-300 ease-in-out hover:-translate-y-3 hover:shadow-2xl"
                  data-aos="zoom-in"
                  data-aos-delay={index * 150} // Staggered delay after the recent news
                >
                  <img
                    src={item.img}
                    alt={item.alt}
                    className="rounded-t-xl drop-shadow-xl"
                  />
                  <div className="flex flex-col p-4 items-center justify-center flex-grow">
                    <h5 className="self-start text-base sm:text-lg md:text-xl lg:text-2xl 2xl:text-3xl font-semibold">
                      {truncateText(item.title, 50)}
                    </h5>
                    <p className="mt-2 md:mt-2 xl:mt-3">
                      {truncateText(item.desc, 100)}
                    </p>
                    <button
                      className="my-3 md:mt-4 xl:mt-5 self-start text-white bg-peach hover:text-peach hover:bg-white font-semibold
          border-2 border-peach hover:border-peach p-1 px-3 md:p-2 md:px-5 font-arimo
          rounded-full text-base md:text-lg lg:text-xl"
                    >
                      {item.date}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </>
        </Layout>
        <div className="pt-8 md:pt-12 lg:pt-16 xl:pt-20">
          <BannerMain />
        </div>
        <SocialMedia />
      </div>
      <div className="-mt-12 sm:-mt-20 md:-mt-24 xl:-mt-28">
        {contactus.map((val, index) => (
          <img
            key={index}
            src={val.shape}
            alt="ELF-Shipping-bg-Image"
            className="w-1/3 h-1/3 md:w-1/4 md:h-1/4 lg:w-1/5 lg:h-1/6"
            data-aos="fade-up"
          />
        ))}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default News;
